<template>
  <form class="mt-8" @submit.prevent="login">
    <input type="hidden" name="remember" value="true" />
    <div class="rounded-md shadow-sm">
      <div>
        <input
          v-model="userEmail"
          aria-label="Email address"
          name="email"
          type="email"
          required
          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-brand focus:z-10 sm:text-sm sm:leading-5"
          placeholder="Email address"
          @blur="handleBlur('userEmail')"
        />
      </div>
      <div class="-mt-px">
        <input
          v-model="userPassword"
          aria-label="Password"
          name="password"
          type="password"
          required
          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-brand focus:z-10 sm:text-sm sm:leading-5"
          placeholder="Password"
          @blur="handleBlur('userPassword')"
          @keyup="handleKeyUp"
        />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-between">
      <div class="flex items-center">
        <input
          id="remember_me"
          v-model="rememberMe"
          type="checkbox"
          class="form-checkbox h-4 w-4 text-brand transition duration-150 ease-in-out"
        />
        <label
          for="remember_me"
          class="ml-2 block text-sm leading-5 text-gray-900"
        >
          Remember me
        </label>
      </div>

      <div class="text-sm leading-5">
        <a
          href="#"
          class="font-medium text-brand hover:text-brand-dark focus:outline-none focus:underline transition ease-in-out duration-150"
        >
          Forgot your password?
        </a>
      </div>
    </div>

    <div class="mt-6">
      <button
        :disabled="!!errorMessage || loading"
        type="submit"
        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-brand hover:bg-brand-dark focus:outline-none focus:border-brand-dark active:bg-brand-light transition duration-150 ease-in-out disabled:opacity-50 disabled:text-gray-500"
      >
        <span class="absolute left-0 inset-y-0 flex items-center pl-3">
          <svg
            class="h-5 w-5 text-white group-hover:text-brand-light transition ease-in-out duration-150"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <!-- <fa v-if="loading" icon="spinner" size="lg" spin></fa> -->
        <div v-if="loading" class="loader" />
        <p v-else>Sign in</p>
      </button>
      <p class="text-xs text-red-500 font-semibold text-center mt-2">
        {{ errorMessage }}
      </p>
    </div>
  </form>
</template>

<script lang="ts">
import { RouteName } from '../../router/RouteName'
import { defineComponent } from 'vue'
import { login } from '@/firebase'

export default defineComponent({
  data() {
    return {
      userEmail: '',
      userPassword: '',
      rememberMe: true,
      errorMessage: '',
      loading: false,
    }
  },
  methods: {
    handleBlur(field: string) {
      this.errorMessage = ''
      if (field === 'userEmail') {
        this.checkForEmailValidation(this.userEmail)
      } else if (field === 'userPassword') {
        this.checkForPasswordValidation(this.userPassword)
      }
    },
    handleKeyUp() {
      this.errorMessage = ''
      this.checkForEmailValidation(this.userEmail)
      this.checkForPasswordValidation(this.userPassword)
    },
    async login() {
      this.loading = true
      this.errorMessage = ''
      if (
        !this.checkForEmailValidation(this.userEmail) ||
        !this.checkForPasswordValidation(this.userPassword)
      ) {
        this.loading = false
        return
      }

      // Sign in with firebase auth
      try {
        const res = await login(
          this.userEmail,
          this.userPassword,
          this.rememberMe
        )

        if (res === 'success') {
          this.$router.push({ name: RouteName.DASHBOARD })
          return
        }
        if (res === 'user-disabled') {
          this.errorMessage =
            'This account is disabled. Please contact support.'
          this.loading = false
        } else if (res === 'incorrect') {
          this.errorMessage = 'Your email or password is incorrect.'
          this.loading = false
        }
      } catch (error) {
        this.errorMessage = error.message
        this.loading = false
      }
    },
    checkForEmailValidation(email: string) {
      const valid = !!email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)
      if (!valid) {
        this.errorMessage = 'Email is invalid.'
      }
      return valid
    },
    checkForPasswordValidation(password: string) {
      const valid = !!password && password.length > 5
      if (!valid) {
        this.errorMessage = 'Password cannot be empty.'
      }
      return valid
    },
  },
})
</script>

<style scoped>
.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

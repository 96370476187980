<template>
  <div
    v-if="isStaging"
    class="bg-red-600 w-full text-white font-bold text-center p-2 absolute top-0"
  >
    You are currently on Staging site.
  </div>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full -mt-24">
      <div class="flex justify-center">
        <img
          class="h-8 object-cover"
          src="@/assets/logos/taxnitro/taxnitro-logo-full.png"
          alt="TaxNitro logo"
        />
        <img
          class="h-8 ml-3 mt-1 object-cover"
          src="@/assets/logos/rightway/rightway-logo-full.svg"
          alt="RightWayTax logo"
        />
      </div>
      <p class="text-center text-sm text-gray-600 mt-2">
        System Integration Admin
      </p>
      <h3 class="mt-6 text-center text-xl leading-9 font-normal text-gray-700">
        Sign in to your account
      </h3>
      <login-form />
    </div>
    <p class="absolute bottom-0 text-gray-600 text-xs">
      &copy; 2021 Bristol Marketing Group. All rights reserved.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoginForm from '../components/login/LoginForm.vue'

export default defineComponent({
  components: { LoginForm },
  data() {
    return {
      isStaging: process.env.VUE_APP_IS_STAGING === 'true',
    }
  },
})
</script>

<style>
body {
  background: rgb(250, 250, 250);
}
</style>

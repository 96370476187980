
import { defineComponent } from 'vue'
import LoginForm from '../components/login/LoginForm.vue'

export default defineComponent({
  components: { LoginForm },
  data() {
    return {
      isStaging: process.env.VUE_APP_IS_STAGING === 'true',
    }
  },
})


import { RouteName } from '../../router/RouteName'
import { defineComponent } from 'vue'
import { login } from '@/firebase'

export default defineComponent({
  data() {
    return {
      userEmail: '',
      userPassword: '',
      rememberMe: true,
      errorMessage: '',
      loading: false,
    }
  },
  methods: {
    handleBlur(field: string) {
      this.errorMessage = ''
      if (field === 'userEmail') {
        this.checkForEmailValidation(this.userEmail)
      } else if (field === 'userPassword') {
        this.checkForPasswordValidation(this.userPassword)
      }
    },
    handleKeyUp() {
      this.errorMessage = ''
      this.checkForEmailValidation(this.userEmail)
      this.checkForPasswordValidation(this.userPassword)
    },
    async login() {
      this.loading = true
      this.errorMessage = ''
      if (
        !this.checkForEmailValidation(this.userEmail) ||
        !this.checkForPasswordValidation(this.userPassword)
      ) {
        this.loading = false
        return
      }

      // Sign in with firebase auth
      try {
        const res = await login(
          this.userEmail,
          this.userPassword,
          this.rememberMe
        )

        if (res === 'success') {
          this.$router.push({ name: RouteName.DASHBOARD })
          return
        }
        if (res === 'user-disabled') {
          this.errorMessage =
            'This account is disabled. Please contact support.'
          this.loading = false
        } else if (res === 'incorrect') {
          this.errorMessage = 'Your email or password is incorrect.'
          this.loading = false
        }
      } catch (error) {
        this.errorMessage = error.message
        this.loading = false
      }
    },
    checkForEmailValidation(email: string) {
      const valid = !!email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)
      if (!valid) {
        this.errorMessage = 'Email is invalid.'
      }
      return valid
    },
    checkForPasswordValidation(password: string) {
      const valid = !!password && password.length > 5
      if (!valid) {
        this.errorMessage = 'Password cannot be empty.'
      }
      return valid
    },
  },
})
